<template>
  <div class="support-page">
    <div class="readable_content form_content">
      <h5 class="title">{{ title }}</h5>
      <stand-out-block class="light">
        <support-contact-form ref="form" @submitted="onSubmitted"></support-contact-form>

        <div class="alternative-contact">
          <div class="bold">Or email us at:</div>
          <div><a href="mailto:support@mychinaroots.com" class="link">support@mychinaroots.com</a></div>
        </div>
      </stand-out-block>
    </div>
  </div>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock';

import SupportContactForm from './SupportContactForm';

export default {
  metaInfo: {
    title: 'Contact Support',
  },
  computed: {
    title() {
      return 'Need help or have feedback?';
    },
  },
  methods: {
    onSubmitted() {
      this.$toasted.success('Message sent! We’ll get back to you soon.');
      this.$refs.form.cleanup();
    },
  },
  components: {StandOutBlock, SupportContactForm},
  name: 'SupportPage',
};
</script>

<style lang="scss" scoped>
.support-page {
  .readable_content.form_content {
    padding: 0;
    margin-top: 80px;
  }

  .title {
    margin-bottom: 20px;
  }

  .alternative-contact {
    padding-top: 20px;
    margin-top: 24px;
    border-top: 1px solid $neutral-200;
  }

  @media only screen and (max-width: $main-menu-breakpoint) {
    .readable_content.form_content {
      margin-top: 40px;
    }
    .title {
      margin: 0 16px 16px;
    }
  }
}
</style>
