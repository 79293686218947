<template>
  <div class="upload-container">
    <div class="attach-files-link">
      <a @click="selectFiles">+ Attach Files</a>
    </div>
    <input type="file" multiple class="files-input" :ref="filesInputRef" @change="fileInputChange" />

    <transition-group name="fade">
      <div v-for="(attachment, index) in attachments" :key="getAttachmentId(attachment, index)" class="attachment">
        <div class="attachment-name">{{ attachment.name }} ({{ getDisplaySize(attachment.size) }})</div>
        <a v-if="!loading" class="delete" @click="deleteAttachment(index)">Delete</a>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
  data() {
    const maxFileSizeMB = 100;
    return {
      attachments: [],
      filesInputRef: 'files-input',
      maxFileSizeMB: maxFileSizeMB,
      maxFileSize: maxFileSizeMB * 1024 * 1024,
    };
  },
  methods: {
    deleteAttachment(index) {
      this.attachments.splice(index, 1);
    },
    selectFiles() {
      this.$refs[this.filesInputRef].click();
    },
    fileInputChange(event) {
      this.filesSelected(event.target.files);
      this.$refs[this.filesInputRef].value = null;
    },
    filesSelected(files) {
      if (!files.length) {
        return;
      }
      let validFiles = [];
      let hasInvalidFile = false;
      for (let file of files) {
        file.size <= this.maxFileSize ? validFiles.push(file) : (hasInvalidFile = true);
      }
      if (hasInvalidFile) {
        this.handleFilesSelectError(`File max size: ${this.maxFileSizeMB}MB`);
      }
      this.attachments.push(...validFiles);
    },
    handleFilesSelectError(errorText) {
      this.$toasted.error(errorText);
    },
    getDisplaySize(fileSize) {
      if (!fileSize) {
        return '';
      }
      fileSize = Math.floor(fileSize / 1024);
      return fileSize < 1024 ? `${fileSize}KB` : `${Math.floor(fileSize / 1024)}MB`;
    },
    getAttachmentId(attachment, index) {
      return `${attachment}-${index}`;
    },
  },
  name: 'UploadFiles',
};
</script>

<style lang="scss" scoped>
.upload-container {
  margin-top: 10px;
  margin-bottom: 8px;
  .attach-files-link {
    display: block;
    text-align: right;
    a {
      color: $neutral-600;
    }
  }
  .attachment {
    border-bottom: 1px solid $divider-line-light;
    padding: 8px 0;
    &:first-of-type {
      border-top: 1px solid $divider-line-light;
      margin-top: 8px;
    }
    &:last-of-type {
      margin-bottom: 16px;
    }
    display: flex;
    justify-content: space-between;
    margin: 5px 0;

    .attachment-name {
      flex-grow: 1;
      padding-right: 16px;
      flex-wrap: wrap;
      word-break: break-all;
    }
  }
  .files-input {
    display: none;
  }
}
</style>
